/*-----nato sanse font Bengali-----------*/
/* @font-face {
  font-family: "Noto Sans Bengali";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Bengali";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Bengali";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Bengali/NotoSansBengali-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*-----nato sanse font Bengali-----------*/

/*-----nato sanse font Gujarati-----------*/

/* @font-face {
  font-family: "Noto Sans Gujarati";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Gujarati";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Gujarati";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Gujarati/NotoSansGujarati-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*-----nato sanse font Gujarati-----------*/

/*-----nato sanse font marathi-----------*/
/* 
@font-face {
  font-family: "Noto Sans";
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_marathi/NotoSans-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans";
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_marathi/NotoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*-----nato sanse font marathi-----------*/

/*-----nato sanse font tamil-----------*/
/* @font-face {
  font-family: "Noto Sans Tamil";
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Tamil";
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Tamil";
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Tamil/NotoSansTamil-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*-----nato sanse font tamil-----------*/

/*-----nato sanse font Malyalam-----------*/

/* @font-face {
  font-family: "Noto Sans Telugu";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Telugu";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Telugu";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Telugu/NotoSansTelugu-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*----End-nato sanse font Malyalam-----------*/

/*-----nato sanse font Malyalam-----------*/

/* @font-face {
  font-family: "Noto Sans Malayalam";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Malayalam";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Malayalam";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Malayalam/NotoSansMalayalam-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*----End-nato sanse font Malyalam-----------*/

/*-----nato sanse font hindi-----------*/
/* 
@font-face {
  font-family: "Noto Sans Devanagari";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Devanagari";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans Devanagari";
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/Noto_Sans_Devanagari/NotoSansDevanagari-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*----End-nato sanse font hindi-----------*/
/*-----nato sanse font Kannada-----------*/
/* @font-face {
  font-family: 'Noto Sans Kannada';
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Bold.eot');
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Bold.eot?#iefix') format('embedded-opentype'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Bold.woff2') format('woff2'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Bold.woff') format('woff'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Kannada';
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Medium.eot');
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Medium.eot?#iefix') format('embedded-opentype'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Medium.woff2') format('woff2'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Medium.woff') format('woff'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Kannada';
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Regular.eot');
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Regular.eot?#iefix') format('embedded-opentype'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Regular.woff2') format('woff2'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Regular.woff') format('woff'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans Kannada';
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-SemiBold.eot');
  src: url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-SemiBold.woff2') format('woff2'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-SemiBold.woff') format('woff'),
      url('https://staticimg.publishstory.co/stresources/stresources/fonts/Noto_Sans_Kannada/NotoSansKannada-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */


/*----End-nato sanse font Kannada-----------*/

/* eng font Roboto*/
/* 
@font-face {
  font-family: "Roboto";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Bold.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Bold.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Bold.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Medium.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Medium.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Medium.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Black.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Black.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Black.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Light.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Light.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Light.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Regular.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Regular.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Regular.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

/*-----------------End- roboto------------------*/
/*------------------ Montserrat------------------*/

/* @font-face {
  font-family: "Montserrat";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Regular.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Regular.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Bold.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Bold.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Medium.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Medium.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-SemiBold.eot");
  src: url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-SemiBold.woff2") format("woff2"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-SemiBold.woff") format("woff"),
    url("https://pagebuilder.publishstory.co/stresources/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/* eng font Montserrat*/

/*------------------ poppins------------------*/

/* @font-face {
  font-family: "Poppins";
  src: url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Regular.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Regular.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Regular.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Medium.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Medium.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Medium.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-SemiBold.eot");
  src: url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-SemiBold.woff2") format("woff2"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-SemiBold.woff") format("woff"),
    url("https://staticimg.publishstory.co/stresources/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
} */

/*-----------------end- poppins------------------*/

:root {
  /* --defaultFont: "Poppins";
  --montserrat: "Montserrat";
  --roboto: "Roboto";
  --langHindi: "Noto Sans Devanagari";
  --langMalyalam: "Noto Sans Malayalam";
  --langTelugu: "Noto Sans Telugu";
  --langTamil: "Noto Sans Tamil";
  --langGujarati: "Noto Sans Gujarati";
  --langMarathi: "Noto Sans";
  --langBengali: "Noto Sans Bengali";
  --langKannada: "Noto Sans Kannada"; */
  --primary: #dc1b07;
  --secondary: #727272;
  --tertiary: #212121;
  --primaryPale: #fbe3e1;
  --textColor: #979797;
  --textPale: #adadad;
  --bgColor: #fafafa;
  --bgRColor: #000000;
  --lightbg: #f8f8f8;
  --whitetColor: #fff;
  --border: #dadada;
  --borderTwo: #d5d5d5;
  --tagColor: #f3f3f3;
  --alertColor: #feffef;
  --linkColor: #1276d3;
  --activeColor: #6f6f6f;
  --menubg: #f6f6f6;
  --inActiveColor: #bfbfbf;
  --authDateColor: #666666;
  --userName: #4d4d4d;
  --fontSize-10: 10px;
  --fontSize-11: 11px;
  --fontSize-12: 12px;
  --fontSize-13: 13px;
  --fontSize-14: 14px;
  --fontSize-15: 15px;
  --fontSize-16: 16px;
  --fontSize-17: 17px;
  --fontSize-18: 18px;
  --fontSize-19: 19px;
  --fontSize-20: 20px;
  --fontSize-21: 21px;
  --fontSize-22: 22px;
  --fontSize-23: 23px;
  --fontSize-24: 24px;
  --fontSize-25: 25px;
  --fontSize-26: 26px;
  --fontSize-27: 27px;
  --fontSize-28: 28px;
  --fontSize-29: 29px;
  --fontSize-30: 30px;
  --fontSize-60: 60px;
  --fontSize-80: 80px;
  --subheading: 18px;
  --fontWeight4: 400;
  --fontWeight5: 500;
  --fontWeight6: 600;
  --fontWeight7: 700;
  --lineHeight: inherit;
  --letterSpacing: 0;
  --textTransform: none;
}

.theme_3 {
  font-family: "Poppins", sans-serif;
}

.theme_4 {
  font-family: "Montserrat", sans-serif;
}

.theme_5 {
  font-family: "Roboto", sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

body {
  margin: 0;
  padding: 0px;
  font-size: var(--fontSize-14);
  font-weight: var(--fontWeight);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
  letter-spacing: var(--letterSpacing);
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-word;
}

h1 {
  font-size: var(--fontSize-26);
  font-weight: var(--fontWeight7);
  letter-spacing: var(--letterSpacing);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
}

h2 {
  font-size: var(--fontSize-20);
  font-weight: var(--fontWeight7);
  letter-spacing: var(--letterSpacing);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
}

h3 {
  font-size: var(--fontSize-18);
  font-weight: var(--fontWeight6);
  letter-spacing: var(--letterSpacing);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
}

h4 {
  font-size: var(--fontSize-16);
  font-weight: var(--fontWeight6);
  letter-spacing: var(--letterSpacing);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
}

h5 {
  font-size: var(--fontSize-14);
  letter-spacing: var(--letterSpacing);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
}

h6 {
  font-size: var(--fontSize-12);
  font-weight: var(--fontWeight4);
  letter-spacing: var(--letterSpacing);
  line-height: var(--lineHeight);
  text-transform: var(--textTransform);
}

p {
  margin: 0px;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: top;
}

img[src=""] {
  display: none;
}

a {
  text-decoration: none !important;
}

em {
  font-style: italic;
}

dl,
ol,
ul {
  padding-left: 15px;
  list-style: none;
}

.commonListViewAuth:has(span:empty) {
  display: none;
}

a:focus,
a:hover {
  outline: none;
  text-decoration: none;
}

/* .container{margin: 0 auto; position: relative; max-width: 988px !important; width: 100%;} */
.headingRow {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: var(--tertiary);
  margin-bottom: 28px;
  font-family: "Poppins", sans-serif;
}

.headingRow h3 {
  margin: 0px;
  display: flex;
  flex-basis: 100%;
  font-size: var(--fontSize-26);
  line-height: 24px;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
}

.headingRow h3 a {
  color: var(--tertiary);
  position: relative;
  padding-left: 14px;
}

.headingRow h3 a:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 54%;
  transform: translateY(-50%);
  background-color: var(--primary);
  width: 4px;
  height: 24px;
}

.headingRow h3 a:empty {
  display: none;
}

.headingRow span {
  margin-bottom: 0;
  font-size: var(--fontSize-16);
  line-height: 12px;
}

.headingRow span a {
  color: var(--linkColor);
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-44 {
  margin-top: 44px;
}


.mb-44 {
  margin-bottom: 44px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

/* section.mt-44:first-child {
  margin-top: 0px;
} */

.fix-wrapper {
  max-width: 1190px !important;
}

footer {
  overflow: hidden;
}

.custom-pagebuilder.preview .top-bar {
  display: none;
}

.gjs-dashed {
  padding: 0 !important;
  overflow: auto !important;
}

.mt-44:has(.ad_wrapper:empty) {
  display: none;
}

/* .mt-44:has(.col-md-12:empty) {
  display: none;
} */

.mt-44:has(.col-md-8) {
  margin-top: 44px !important;
}


.theme_5 img:hover {
  transform: scale(1, 1);
}

img:hover {
  transform: scale(1.05);
  transition: 0.5s;
}

.as-content-body span>img:hover {
  transform: scale(1);
}

img {
  transition: 0.5s;
}

.hero-layout:hover div>a>span img {
  transform: scale(1.05);
  transition: 0.5s;
}

.listcontainer:hover .listimage img {
  transform: scale(1.05);
  transition: 0.5s;
}

.img-box {
  overflow: hidden;
}

#HeaderlogoIn img:hover,
.footerlogo img:hover {
  transform: scale(1);
}

.custom-pagebuilder img:hover {
  transform: scale(1);
}

.commonset:empty {
  display: none;
}

.commonset:has(span:empty) {
  display: none;
}

.commonset span:empty {
  display: none;
}

.theme_3 .themeonearticalShowAuthr {
  font-family:  "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  color: #6f6f6f;
}

.theme_3 .themeonearticalShowAuthr a {
  font-family:  "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  color: #6f6f6f;
}

/*------------silk slider-----------_*/
/*------------Theme 1 amp-----------_*/

.theme_3 .ampVidoAuthDetails .articalShowAuthr {
  font-family: "Poppins", sans-serif;
  font-size: var(--fontSize-12);
  line-height: 10px;
  font-weight: var(--fontWeight4);
  color: var(--activeColor);
}

.theme_3 .ampVidoAuthDetails .articalShowAuthr a {
  font-family: "Poppins", sans-serif;
  font-size: var(--fontSize-12);
  line-height: 10px;
  font-weight: var(--fontWeight4);
  color: var(--activeColor);
}

.theme_3 #sidebar-left,
.theme_3 .themeOneHeaderAmpSideNav .themeOneHeaderAmpClose {
  display: none;
}

/*------------Theme 1 amp-----------_*/
/*! CSS Used from: Embedded */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: 0px !important;
  margin-right: initial !important;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-initialized .slick-slide {
  display: block;
}

/*! CSS Used from: Embedded */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: var(--whitetColor);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

.slick-prev:before {
  content: "←";
}

.slick-next {
  right: -25px;
}

.slick-next:before {
  content: "→";
}

.slick-dots {
  display: block;
  text-align: center;
  list-style: none;
  line-height: 9px !important;
  padding: 16px 0px 16px;
  bottom: auto;
  position: relative !important;
}

.slick-dots li {
  display: inline-block;
  width: 20px !important;
  height: 20px !important;
  line-height: 12px;
}

.slick-dots li.slick-active button {
  background-color: var(--primary) !important;
}

.slick-dots li button {
  border: 0px;
  width: 9px !important;
  height: 9px !important;
  border-radius: 100%;
  cursor: pointer;
  background-color: var(--inActiveColor) !important;
  font-size: 0px;
  padding: 0px;
}

.slick-dots li button:before {
  font-size: 0px;
  content: "";
}

.slick-dots li:last-child {
  margin-right: 0px !important;
}

.gjs-pn-panel.gjs-pn-views-container.gjs-one-bg.gjs-two-color::-webkit-scrollbar {
  display: none;
}

/*------------silk slider-----------_*/
/*------------Themetwo css-----------_*/

.commonListViewAuth:has(span:empty) {
  display: none;
}

.themeTwodateAgencyWrap {
  margin-top: 4px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.commonListViewAuth {
  margin-right: 8px;
  color: var(--authDateColor);
  font-family: "Montserrat", sans-serif;
  font-size: var(--fontSize-11);
  font-weight: var(--fontWeight5);
  font-style: normal;
  line-height: 17px;
}

.commonListViewAuth:last-child {
  margin-right: 0px;
}

.commonListViewAuth span {
  font-weight: 600;
  color: rgba(0, 0, 0, 0.76);
}

.themeTwoCommonComponentsHeading {
  color: var(--bgRColor);
  font-family: "Montserrat", sans-serif;
  font-size: var(--fontSize-24);
  font-weight: var(--fontWeight7);
  font-style: normal;
  line-height: 36px;
  margin: 0px 0px 30px;
  text-transform: uppercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ThemeTwoTrnHeading {
  color: var(--bgRColor);
  font-family: "Montserrat", sans-serif;
  font-size: var(--fontSize-24);
  font-weight: var(--fontWeight7);
  font-style: normal;
  line-height: 36px;
  margin: 0px 0px 30px;
  text-transform: uppercase;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.muiGridRow {
  /* display: flex; */
  /* flex-wrap: wrap; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.muiGridRowWebStroy {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

.gridForListingPage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 18px;
}

.muiGridRowWebStroy.muiGridRowPodList {
  grid-gap: 18px;
}

.muiGridRowPhotoList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.muiGridRow.muiGridRowHome,
.muiGridRowPhotoList.muiGridRowPhotoListGap {
  grid-gap: 28px;
}

.commonSocialListWrap li:nth-child(n + 6) {
  display: none;
}

.modal-open {
  overflow: hidden;
}

.commonListViewAuth:has(.listAuthor:empty) {
  display: none;
}

.themeTwoAmpArticalMedia .svg-inline--fa.fa-youtube {
  border: 1px solid red !important;
  color: red;
}

.themeTwoAmpArticalMedia .svg-inline--fa.fa-linkedin {
  border: 1px solid #0a66c2 !important;
  color: #0a66c2;
}

.themeTwoAmpArticalMedia .svg-inline--fa.fa-instagram {
  border: 1px solid #e4405f !important;
  color: #e4405f;
}

.themeTwoAmpArticalMedia .svg-inline--fa.fa-pinterest-p {
  border: 1px solid #e53333;
  color: #e53333;
  border: 0px;
}

.themeTwoAmpArticalMedia .svg-inline--fa.fa-playstation {
  border: 1px solid #000;
  color: #000;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-instagram {
  color: #c32aa3;
  border-color: #c32aa3;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-facebook-f {
  color: #1877f2;
  border-color: #1877f2;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-twitter {
  color: #1da1f2;
  border-color: #1da1f2;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-youtube {
  color: #ff0000;
  border-color: #ff0000;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-linkedin {
  color: #0a66c2;
  border-color: #0a66c2;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-pinterest-p {
  color: #bd081c;
  border-color: #bd081c;
}

.theme_4 .footerSocialList svg.svg-inline--fa.fa-playstation {
  color: #ea4c89;
  border-color: #ea4c89;
}

.theme_4 .articalShowAuthr {
  gap: 2px;
}

.theme_4 .themeTwoArticalAuthorWrap,
.theme_4 .articalShowAuthr {
  align-items: center;
  color: #666;
  display: flex;
  flex-wrap: wrap;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
}

.theme_4 .articalShowAuthr a {
  color: #666;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 17px;
}

/*------------Themetwo css-----------_*/
/*------------ThemeThree Common css-----------_*/
.theme_5 .themeTwoArticalAuthorWrap,
.theme_5 .articalShowAuthr {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

}

.theme_5 .articalShowAuthr a {
  font-family: "Roboto", sans-serif;
  color: #8C8C8C;
  font-weight: 500;
}

.theme_5 img:hover {
  transform: scale(1);
}

.themeThreedateAgencyWrap {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.ThemeThreeCommonAuthorWrapper {
  color: #8c8c8c;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  margin-right: 10px;
  line-height: 16px;
  /* 133.333% */
}

.themeThreeHeroLayoutDateTimeAgency,
.themeThreeHeroLayoutDateTimeAgency span {
  color: rgba(0, 0, 0, 0.76);
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.themeThreeHeroLayoutDateTimeAgency {
  color: #8c8c8c;
}

.themeThreeHeroLayoutDateTimeAgency:has(span:empty) {
  display: none;
}

.InsideCommonTags {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  /* 92.308% */
  text-transform: uppercase;
  border-radius: 2px;
  background: #f29d38;
  display: inline-block;
  padding: 4px 7px;
  margin-right: 10px;
  margin-bottom: 4px;
}

.InsideCommonTags:empty {
  display: none;
}

.ThemeThreeHeadingMainWrapper {
  text-align: center;
}
.ThemeThreeHeroNavHeadingWrap{
  text-align: center;
}

.ThemeThreeCommonSubHeading {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  /* 133.333% */
}

.themeThreeCommonComponentsHeading {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 33px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  text-transform: capitalize;
  margin: 16px 0px 50px;
}

.themeThreeCommonComponentsHeading a {
  font-family: "Roboto", sans-serif;
  font-size: 33px;
  line-height: 40px;
  overflow: hidden;
  display: -webkit-inline-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.AngleIconWrap svg {
  width: 6px;
  height: 9.717px;
  flex-shrink: 0;
  color: #000;
}

.AngleIconWrap {
  margin-right: 10px;
}

.articalShowThreeAuthr,
.articalShowThreeAuthr a {
  color: #8c8c8c;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  /* 121.429% */
}

.articalShowThreeAuthr a {
  color: rgba(0, 0, 0, 0.76);
}

.theme_5 .tags-heading {
  color: #000;
  margin: 7px 10px 0px 0px;
  position: relative;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  width: 50px;
  margin-bottom: 10px;
  flex-shrink: 0;
}


.theme_5 .tag-lists {
  display: flex;
  flex-wrap: wrap;
}

.theme_5 .tag-lists li {
  margin-right: 10px;
  margin-bottom: 10px;
}

.theme_5 .tag-lists li:last-child {
  margin-right: 0px;
}

.theme_5 .tag-lists li a {
  display: block;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  /* 143.75% */
  padding: 6px 10px;
  border-radius: 2px;
  background: #fff;
  -webkit-box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 9px -4px rgba(0, 0, 0, 0.3);
}

.theme_5 .ThemeThreeHomeOtherPageCommonHeading {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.theme_5 .ThemeThreeHomeOtherPageCommonHeading a {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.theme_5 .tags-wrap {
  margin-bottom: 50px;
}

/* body.theme_5{
  background: #F9F8F8;
}
body.theme_5.HomeBodyThemeThree{
  background-color: #fff;
} */


.theme_5 {
  background: #f9f8f8;
}

/* .HeroLayotuSkeleton {
  background: #F9F8F8;
}

.HomeGridViewSkeleton {
  background: #fff;
  padding: 80px 0px;
}

.HomeWebFaqSkeleton {
  background: #fff;
}

.HomeQuickViewkeleton {
  background: #fff;
  padding: 80px 0px;
} */

.ThemeThreeAccordianWrapper .MuiAccordionSummary-expandIconWrapper {
  transform: rotate(270deg);
}

.ThemeThreeAccordianWrapper .Mui-expanded.MuiAccordionSummary-expandIconWrapper {
  transform: rotate(360deg);
}

/*------------Common Embed css-----------_*/
.CommonEmbedSlider.slick-initialized .slick-slide {
  margin-right: 38px;
  max-width: 216px;
}

.CommonEmbedSlider.slick-initialized {
  padding: 60px 20px 10px;
  background: #fff;
}

.CustomPrevArrow, .CustomNextArrow {
  position: absolute;
  right: 20px;
  top: 15px;
}

.CommonEmbedSlider.slick-initialized h2 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 10px 0px 0px;
}

.CustomPrevArrow {
  right: 60px;
}

.CustomPrevArrow button svg, .CustomNextArrow button svg {
  border-radius: 100%;
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}

.CustomPrevArrow button, .CustomNextArrow button {
  width: 30px;
  height: 30px;
  background: #D9D9D9;
}

.CommonEmbedDiscription {
  display: none;
}

/*----------Poll--Common Embed css-----------_*/
.ComonEmbedPoll {
  background: #fff;
  padding: 22px;
  position: relative;
}

.ComonEmbedPoll:before {
  content: '';
  position: absolute;
  background: #F29D38;
  height: 60px;
  top: 0px;
  left: 0px;
  width: 2px;
}

.theme_5 .ComonEmbedPoll .ComonEmbedPollHeading {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: left;
}

.theme_5 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
  color: #F29D38;
  margin: 0px 6px 0px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
}

.CommonInsidePolInput {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid #1F242933;
  margin-bottom: 15px;
  border-radius: 10px;
  padding: 0px 10px 0px 0px;
  overflow: hidden;
}

.CommonInsidePolInput li {
  padding: 8px 4px;
  position: relative;
}

.CommonInsidePolInput li:after {
  content: '';
  position: absolute;
  right: 0px;
  width: 0;
  height: 0;
  top: 0px;
  border-top: 0px solid transparent;
  border-bottom: 43px solid transparent;
  border-right: 30px solid #ffffff;
}

.theme_5 .CommonInsidePolInput .ComonEmbedPollDiscriptionWrapper {
  position: absolute;
  left: 34px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.ComonEmbedPollSubmitWrapper {
  /* margin-left: 20px; */
  margin-left: auto;
}

.CommonInsidePolInput li input[type="radio"] {
  accent-color: #77D48B;
  position: relative;
  z-index: 99;
}

/*----------Quiz Survey--Common Embed css-----------_*/
.CommonEmbedQuizSurveyShowWrapper {
  background: #fff;
  padding: 22px;
  position: relative;
}

.CommonEmbedQuizSurveyShowWrapper:before {
  content: '';
  width: 2px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 60px;
  background: #F29D38;
}

.theme_5 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
}

.theme_5 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_5 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 500;
  display: flex;
  margin-left: 4px;
}

.theme_5 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizAuthorName span {
  margin-right: 4px;
  margin-bottom: 0px;
}

.theme_5 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}

.CommonEmbedSurveyButton {
  border-top: 1px solid #D3CECE;
  padding-top: 20px;
  margin-top: 20px;
}

.CommonEmbedSurveyButton button {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: 0px;
  text-transform: uppercase;
  background: #E21B22;
  color: #fff;
  padding: 9px 25px;
  border-radius: 8px;
  cursor: pointer;
}

/*---------Artile---Common Embed css-----------_*/
.CommonEmbedArtileShowWrapper {
  background: #fff;
  padding: 20px;
  position: relative;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedArtileImageWrap {
  width: 120px;
  height: 90px;
  margin-right: 20px;
  flex-shrink: 0;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedArtileImageWrap img {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedArtileTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.theme_5 .CommonEmbedArtileShow {
  display: flex;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 500;
  display: flex;
  margin-left: 4px;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName span {
  margin-right: 4px;
  margin-bottom: 0px;
}

.theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}

.theme_5 .CommonEmbedArtileShowWrapper .CommonEmbedSurveyQuizSynopsisMobile {
  display: none;
}

/*---------Document---Common Embed css-----------_*/
.theme_5 .CommonEmbedFileDownload {
  display: flex;
}

.theme_5 .CommonEmbedFileDownload span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  margin-bottom: 0px;
}

.theme_5 .CommonEmbedFileDownload a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  margin-right: 10px;
  text-decoration: underline !important;
}

.theme_5 .CommonEmbedFileDownload a svg {
  margin-left: 10px;
}

.theme_5 .CommonEmbedFileDownload a svg {
  width: 23px;
  font-size: 23px;
}

blockquote.instagram-media {
  margin: 0px;
}

.CommonEmbedInsta {
  text-align: center;
}

.CommonEmbedInsta iframe {
  margin: 0 auto !important;
}

.CommonEmbedInstaCustom {
  text-align: center;
}

/*---------Quote---Common Embed css-----------_*/
.CommonEmbedQuoteWrapper {
  background: #fff;
  padding: 20px;
  position: relative;
}

.theme_5 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  position: relative;
}

.theme_5 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
}

.theme_5 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  position: absolute;
}

.theme_5 .CommonEmbedQuoteWrapper .CommonEmbedQuotesIcon {
  margin-bottom: 10px;
  line-height: 1;
}

.theme_5 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor:before {
  content: '';
  width: 10px;
  height: 2px;
  background: #F29D38;
  position: absolute;
  left: 6px;
}

/*------------Common Embed css-----------_*/
/*------------Common GroupEmbed css-----------_*/

.theme_5 .CommonEmbedGroupSlider {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background: #F0F0F0;
  padding: 50px 0px 40px 35px;
  margin: 40px 0px;
  border: 1px solid rgb(204, 204, 204);
}

.CommonEmbedGroupSlider.slick-initialized .slick-slide {
  margin-right: 40px;
  min-width: 402px;
  max-width: 402px;
}

.theme_5 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper {
  height: 286px;
  max-height: 286px;
  background: #fff;
  position: relative;
  padding: 20px 20px 26px;
  overflow-y: scroll;
}

.theme_5 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar {
  width: 4px;
}

.theme_5 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.theme_5 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar-thumb {
  background-color: #202020;
  border-radius: 100px;
}

.CommonEmbedGroupItemsInnerWrapper:before {
  content: '';
  width: 2px;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 60px;
  background: #F29D38;
}

/*------------Common Survey Quiz GroupEmbed css-----------_*/
.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupHeading {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #F29D38;
  margin-bottom: 10px;

}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupTitle {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000;
  margin-bottom: 3px;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 500;
  display: flex;
  margin-left: 4px;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; */
  margin-bottom: 0px;
}

.CommonEmbedSurveyButton {
  border-top: 1px solid #D3CECE;
  padding-top: 20px;
  margin-top: 20px;
}

.CommonEmbedSurveyButton button {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: 0px;
  text-transform: uppercase;
  background: #E21B22;
  color: #fff;
  padding: 9px 25px;
  border-radius: 8px;
  cursor: pointer;
}

/*------------Common Survey Quiz GroupEmbed css-----------_*/
/*------------Common SlidShow GroupEmbed css-----------_*/
.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowWrap {
  position: relative;
  height: 100%;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowWrap img {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowTitle {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000;
  position: absolute;
  bottom: 0px;
  padding: 10px;
}

/*------------Common SlidShow GroupEmbed css-----------_*/
/*------------Common Poll GroupEmbed css-----------_*/
.CommonEmbedGroupItemsInnerWrapper .ComonEmbedPoll {
  padding: 0px;
}

.CommonEmbedGroupItemsInnerWrapper .ComonEmbedPoll::before {
  display: none;
}

.CommonEmbedGroupItemsInnerWrapper .ComonEmbedPoll .ComonEmbedPollDefaultText {
  display: none;
}

.CommonEmbedGroupItemsInnerWrapper .ComonEmbedPoll .ComonEmbedPollHeading {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfImageWrap {
  position: relative;
}


.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfIconWrap {
  position: absolute;
  right: 0px;
  bottom: 10px;
  display: flex;
  align-items: center;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfImageWrap img {
  margin-bottom: 0px;
}

.CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfIconWrap svg:first-child {
  margin-right: 10px;
}

/*------------Common Poll GroupEmbed css-----------_*/
/*------------Common Article GroupEmbed css-----------_*/

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupArticleImageWrap img {
  width: 86px;
  height: 65px;
  float: left;
  margin: 4px 15px 0px 0px;
}

.theme_5 .CommonEmbedGroupArticleSynopsis img {
  margin-left: 100px;
  margin-top: 6px;
}

/*------------Common Article GroupEmbed css-----------_*/
/*------------Common Video GroupEmbed css-----------_*/

/* .CommonEmbedGroupMediaPlayerWrapper.responsive-player {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.CommonEmbedGroupMediaPlayerWrapper.responsive-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  border: none;
}
.CommonEmbedGroupMediaPlayerWrapper.responsive-player iframe.__fHW{
  height: 100%;
} */
/*------------Common Video GroupEmbed css-----------_*/
/*------------Common Quotes GroupEmbed css-----------_*/

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupSummary {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  position: relative;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  position: absolute;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuotesIcon {
  margin-bottom: 10px;
  line-height: 1;
}

.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
}


.theme_5 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor:before {
  content: '';
  width: 10px;
  height: 2px;
  background: #F29D38;
  position: absolute;
  left: 6px;
}

/*------------Common quotes GroupEmbed css-----------_*/

.theme_5 .CommonEmbedGroupSlider .slick-dots li.slick-active button {
  background-color: #000 !important;
}

.theme_5 .CommonEmbedGroupSlider .slick-dots li button:before {
  content: '';
}

.theme_5 .CommonEmbedGroupSlider .slick-dots li {
  margin: 0px;
}

/*------------Common GroupEmbed css-----------_*/
/*------------ThemeThree Common css-----------_*/
/*-----------theme Two Embeds ---------------*/


/*----------Poll--Common Embed css-----------_*/


.theme_4 .ComonEmbedPoll .ComonEmbedPollHeading {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: left;
}

.theme_4 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
  color: #F29D38;
  margin: 0px 6px 0px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}


.theme_4 .CommonInsidePolInput .ComonEmbedPollDiscriptionWrapper {
  position: absolute;
  left: 34px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}


/*----------Quiz Survey--Common Embed css-----------_*/


.theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
}

.theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 600;
  display: flex;
  margin-left: 4px;
}

.theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizAuthorName span {
  margin-right: 4px;
  margin-bottom: 0px;
}

.theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}

/*---------Artile---Common Embed css-----------_*/


.theme_4 .CommonEmbedArtileShow .CommonEmbedArtileImageWrap {
  width: 120px;
  height: 90px;
  margin-right: 20px;
  flex-shrink: 0;
}

.theme_4 .CommonEmbedArtileShow .CommonEmbedArtileImageWrap img {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.theme_4 .CommonEmbedArtileShow .CommonEmbedArtileTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.theme_4 .CommonEmbedArtileShow {
  display: flex;
}

.theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 600;
  display: flex;
  margin-left: 4px;
}

.theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName span {
  margin-right: 4px;
  margin-bottom: 0px;
}

.theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}

.theme_4 .CommonEmbedArtileShowWrapper .CommonEmbedSurveyQuizSynopsisMobile {
  display: none;
}

.theme_4 .CommonEmbedSlideShowMainWrapper {
  position: relative;
}

.theme_4 .CommonEmbedSlideShowMainWrapper .CommonEmbedSlideShowTitle {
  position: absolute;
  top: 20px;
  z-index: 9;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #000;
  left: 20px;
}


/*---------Document---Common Embed css-----------_*/
.theme_4 .CommonEmbedFileDownload {
  display: flex;
}

.theme_4 .CommonEmbedFileDownload span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  margin-bottom: 0px;
}

.theme_4 .CommonEmbedFileDownload a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  margin-right: 10px;
  text-decoration: underline !important;
}

.theme_4 .CommonEmbedFileDownload a svg {
  margin-left: 10px;
}

.theme_4 .CommonEmbedFileDownload a svg {
  width: 23px;
  font-size: 23px;
}

/*---------Quote---Common Embed css-----------_*/

.theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  position: relative;
}

.theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
}

.theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  position: absolute;
}

.theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuotesIcon {
  margin-bottom: 10px;
  line-height: 1;
}

.theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor:before {
  content: '';
  width: 10px;
  height: 2px;
  background: #F29D38;
  position: absolute;
  left: 6px;
}

/*------------Common Embed css-----------_*/
/*------------Common GroupEmbed css-----------_*/

.theme_4 .CommonEmbedGroupSlider {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background: #F0F0F0;
  padding: 50px 0px 40px 35px;
  margin: 40px 0px;
  border: 1px solid rgb(204, 204, 204);
}

.theme_4 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper {
  height: 286px;
  max-height: 286px;
  background: #fff;
  position: relative;
  padding: 20px 20px 26px;
  overflow-y: scroll;
}

.theme_4 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar {
  width: 4px;
}

.theme_4 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.theme_4 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar-thumb {
  background-color: #202020;
  border-radius: 100px;
}

/*------------Common Survey Quiz GroupEmbed css-----------_*/
.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupHeading {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #F29D38;
  margin-bottom: 10px;

}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupTitle {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000;
  margin: 10px 0px;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 600;
  display: flex;
  margin-left: 4px;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; */
  margin-bottom: 0px;
}

/*------------Common Survey Quiz GroupEmbed css-----------_*/
/*------------Common SlidShow GroupEmbed css-----------_*/
.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowWrap {
  position: relative;
  height: 100%;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowWrap img {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowTitle {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000;
  position: absolute;
  bottom: 0px;
  padding: 10px;
}

/*------------Common SlidShow GroupEmbed css-----------_*/
/*------------Common Poll GroupEmbed css-----------_*/

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfImageWrap {
  position: relative;
}


.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfIconWrap {
  position: absolute;
  right: 0px;
  bottom: 10px;
  display: flex;
  align-items: center;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfImageWrap img {
  margin-bottom: 0px;
}

/*------------Common Poll GroupEmbed css-----------_*/
/*------------Common Article GroupEmbed css-----------_*/

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupArticleImageWrap img {
  width: 86px;
  height: 65px;
  float: left;
  margin: 4px 15px 0px 0px;
}

.theme_4 .CommonEmbedGroupArticleSynopsis img {
  margin-left: 100px;
  margin-top: 6px;
}

/*------------Common Article GroupEmbed css-----------_*/

/*------------Common Quotes GroupEmbed css-----------_*/

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupSummary {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  position: relative;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  position: absolute;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuotesIcon {
  margin-bottom: 10px;
  line-height: 1;
}

.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
}


.theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor:before {
  content: '';
  width: 10px;
  height: 2px;
  background: #F29D38;
  position: absolute;
  left: 6px;
}

.theme_4 .CommonEmbedGroupSlider .slick-dots li.slick-active button {
  background-color: #000 !important;
}

.theme_4 .CommonEmbedGroupSlider .slick-dots li button:before {
  content: '';
}

.theme_4 .CommonEmbedGroupSlider .slick-dots li {
  margin: 0px;
}

/*------------Common quotes GroupEmbed css-----------_*/

/*------------Common GroupEmbed css-----------_*/

/*---------End Of--theme Two Embeds ---------------*/

/*-----------theme One Embeds ---------------*/


/*----------Poll--Common Embed css-----------_*/


.theme_3 .ComonEmbedPoll .ComonEmbedPollHeading {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: left;
}

.theme_3 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
  color: #F29D38;
  margin: 0px 6px 0px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
}


.theme_3 .CommonInsidePolInput .ComonEmbedPollDiscriptionWrapper {
  position: absolute;
  left: 34px;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}


/*----------Quiz Survey--Common Embed css-----------_*/


.theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
}

.theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 600;
  display: flex;
  margin-left: 4px;
}

.theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizAuthorName span {
  margin-right: 4px;
  margin-bottom: 0px;
}

.theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}

/*---------Artile---Common Embed css-----------_*/


.theme_3 .CommonEmbedArtileShow .CommonEmbedArtileImageWrap {
  width: 120px;
  height: 90px;
  margin-right: 20px;
  flex-shrink: 0;
}

.theme_3 .CommonEmbedArtileShow .CommonEmbedArtileImageWrap img {
  width: 100%;
  height: 100%;
  margin: 0px;
}

.theme_3 .CommonEmbedArtileShow .CommonEmbedArtileTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.theme_3 .CommonEmbedArtileShow {
  display: flex;
}

.theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 600;
  display: flex;
  margin-left: 4px;
}

.theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName span {
  margin-right: 4px;
  margin-bottom: 0px;
}

.theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 0px;
}

.theme_3 .CommonEmbedArtileShowWrapper .CommonEmbedSurveyQuizSynopsisMobile {
  display: none;
}

.theme_3 .CommonEmbedSlideShowMainWrapper {
  position: relative;
}

.theme_3 .CommonEmbedSlideShowMainWrapper .CommonEmbedSlideShowTitle {
  position: absolute;
  top: 20px;
  z-index: 9;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #000;
  left: 20px;
}


/*---------Document---Common Embed css-----------_*/
.theme_3 .CommonEmbedFileDownload {
  display: flex;
}

.theme_3 .CommonEmbedFileDownload span {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  margin-bottom: 0px;
}

.theme_3 .CommonEmbedFileDownload a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 27px;
  margin-right: 10px;
  text-decoration: underline !important;
}

.theme_3 .CommonEmbedFileDownload a svg {
  margin-left: 10px;
}

.theme_3 .CommonEmbedFileDownload a svg {
  width: 23px;
  font-size: 23px;
}

/*---------Quote---Common Embed css-----------_*/

.theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  position: relative;
}

.theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
}

.theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  position: absolute;
}

.theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuotesIcon {
  margin-bottom: 10px;
  line-height: 1;
}

.theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor:before {
  content: '';
  width: 10px;
  height: 2px;
  background: #F29D38;
  position: absolute;
  left: 6px;
}

/*------------Common Embed css-----------_*/
/*------------Common GroupEmbed css-----------_*/

.theme_3 .CommonEmbedGroupSlider {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2509803922);
  background: #F0F0F0;
  padding: 50px 0px 40px 35px;
  margin: 40px 0px;
  border: 1px solid rgb(204, 204, 204);
}

.theme_3 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper {
  height: 286px;
  max-height: 286px;
  background: #fff;
  position: relative;
  padding: 20px 20px 26px;
  overflow-y: scroll;
}

.theme_3 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar {
  width: 4px;
}

.theme_3 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.theme_3 .CommonEmbedGroupSlider .CommonEmbedGroupItemsInnerWrapper::-webkit-scrollbar-thumb {
  background-color: #202020;
  border-radius: 100px;
}

/*------------Common Survey Quiz GroupEmbed css-----------_*/
.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupHeading {
  font-family: "Roboto", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #F29D38;
  margin-bottom: 10px;

}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupTitle {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000;
  margin: 10px 0px;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  margin-bottom: 4px;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTimeWrap {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
  color: #8C8C8C;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizAuthorName {
  color: #000000c2;
  font-weight: 600;
  display: flex;
  margin-left: 4px;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizSynopsis {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #606060;
  /* display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; */
  margin-bottom: 0px;
}

/*------------Common Survey Quiz GroupEmbed css-----------_*/
/*------------Common SlidShow GroupEmbed css-----------_*/
.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowWrap {
  position: relative;
  height: 100%;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowWrap img {
  margin: 0px;
  width: 100%;
  height: 100%;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowTitle {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000;
  position: absolute;
  bottom: 0px;
  padding: 10px;
}

/*------------Common SlidShow GroupEmbed css-----------_*/
/*------------Common Poll GroupEmbed css-----------_*/

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfImageWrap {
  position: relative;
}


.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfIconWrap {
  position: absolute;
  right: 0px;
  bottom: 10px;
  display: flex;
  align-items: center;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupPdfImageWrap img {
  margin-bottom: 0px;
}

/*------------Common Poll GroupEmbed css-----------_*/
/*------------Common Article GroupEmbed css-----------_*/

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupArticleImageWrap img {
  width: 86px;
  height: 65px;
  float: left;
  margin: 4px 15px 0px 0px;
}

.theme_3 .CommonEmbedGroupArticleSynopsis img {
  margin-left: 100px;
  margin-top: 6px;
}

/*------------Common Article GroupEmbed css-----------_*/

/*------------Common Quotes GroupEmbed css-----------_*/

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupSummary {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  position: relative;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon {
  font-family: "Roboto", sans-serif;
  font-size: 70px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #000;
  margin-bottom: 0px;
  position: absolute;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuotesIcon {
  margin-bottom: 10px;
  line-height: 1;
}

.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 20px;
  position: relative;
}


.theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor:before {
  content: '';
  width: 10px;
  height: 2px;
  background: #F29D38;
  position: absolute;
  left: 6px;
}

.theme_3 .CommonEmbedGroupSlider .slick-dots li.slick-active button {
  background-color: #000 !important;
}

.theme_3 .CommonEmbedGroupSlider .slick-dots li button:before {
  content: '';
}

.theme_3 .CommonEmbedGroupSlider .slick-dots li {
  margin: 0px;
}

/*------------Common quotes GroupEmbed css-----------_*/

/*------------Common GroupEmbed css-----------_*/

/*---------End Of--theme One Embeds ---------------*/


.loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f3f3f3;
  /* Semi-transparent white background */
  z-index: 9999;
  /* Ensure loader appears above other content */
  justify-content: center;
  align-items: center;
}

.loader.show {
  display: flex;
}

.loader-content {
  text-align: center;
}

.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #333;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
    /* Rotate the circle 360 degrees */
  }
}

/*------------Common GroupEmbed css-----------_*/
.theme_3 .ComonEmbedPoll .ComonEmbedPollHeading, .theme_3 .ComonEmbedPollHeading .ComonEmbedPollDefaultText, .theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTitle, .theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTimeWrap, .theme_3 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizSynopsis, .CommonEmbedSurveyButton button, .theme_3 .CommonEmbedArtileShow .CommonEmbedArtileTitle, .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap, .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsis, .theme_3 .CommonEmbedSlideShowMainWrapper .CommonEmbedSlideShowTitle, .theme_3 .CommonEmbedFileDownload span, .theme_3 .CommonEmbedFileDownload a, .theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery, .theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor, .theme_3 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupHeading, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupTitle, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTitle, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTimeWrap, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizSynopsis, .CommonEmbedSurveyButton button, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowTitle, .theme_3 .CommonEmbedGroupItemsInnerWrapper .ComonEmbedPoll .ComonEmbedPollHeading, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupSummary, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon, .theme_3 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor, .theme_3 .CommonEmbedSlider.slick-initialized h2 {
  font-family: var(--defaultFont);
}

.theme_3 .CommonEmbedSlideeShowImageWrap {
  min-width: 216px;
  height: 162px;
  overflow: hidden;

}

.CommonEmbedSlideeShowImageWrap img {
  height: 100% !important;
  width: 100% !important;
}


.theme_3 .CommonEmbedSlider.slick-initialized {
  padding: 60px 20px 10px;
  box-shadow: 0px 1px 4px 0px #00000040;
  background: #F5F5F5;
}

.theme_3 .as-content-body .CommonEmbedQuoteWrapper a, .theme_3 .as-content-body .CommonEmbedSlideShowMainWrapper a, .theme_3 .as-content-body .CommonEmbedQuizSurveyShowWrapper a, .theme_3 .as-content-body .CommonEmbedArtileShowWrapper a {
  text-decoration: none !important;
}

.theme_3 .CommonEmbedQuizSurveyShowWrapper, .theme_3 .CommonEmbedArtileShowWrapper {
  background-color: #F5F5F5;
}

.theme_3 .CommonEmbedQuizSurveyShowWrapper::before {
  display: none;
}

/*---------End Of--theme One Embeds ---------------*/
.theme_4 .ComonEmbedPoll .ComonEmbedPollHeading, .theme_4 .ComonEmbedPollHeading .ComonEmbedPollDefaultText, .theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTitle, .theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizTimeWrap, .theme_4 .CommonEmbedQuizSurveyShow .CommonEmbedSurveyQuizSynopsis, .CommonEmbedSurveyButton button, .theme_4 .CommonEmbedArtileShow .CommonEmbedArtileTitle, .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap, .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsis, .theme_4 .CommonEmbedSlideShowMainWrapper .CommonEmbedSlideShowTitle, .theme_4 .CommonEmbedFileDownload span, .theme_4 .CommonEmbedFileDownload a, .theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery, .theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteAuthor, .theme_4 .CommonEmbedQuoteWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupHeading, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupTitle, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTitle, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizTimeWrap, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedSurveyQuizSynopsis, .CommonEmbedSurveyButton button, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonGroupEmbedSlideShowTitle, .theme_4 .CommonEmbedGroupItemsInnerWrapper .ComonEmbedPoll .ComonEmbedPollHeading, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedGroupSummary, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteSummery .CommonEmbedQuotesIcon, .theme_4 .CommonEmbedGroupItemsInnerWrapper .CommonEmbedQuoteAuthor, .theme_4 .CommonEmbedSlider.slick-initialized h2 {
  font-family: "Montserrat", sans-serif;
}

.theme_4 .as-content-body .CommonEmbedQuoteWrapper a, .theme_4 .as-content-body .CommonEmbedSlideShowMainWrapper a, .theme_4 .as-content-body .CommonEmbedQuizSurveyShowWrapper a, .theme_4 .as-content-body .CommonEmbedArtileShowWrapper a {
  text-decoration: none !important;
}

.theme_4 .CommonEmbedQuizSurveyShowWrapper, .theme_4 .CommonEmbedArtileShowWrapper {
  background-color: #F5F5F5;
}

.theme_4 .CommonEmbedQuizSurveyShowWrapper::before {
  display: none;
}

/*---------End Of--theme One Embeds ---------------*/

/*---------Image credits ---------------*/
.commonImagecreditMainWrapper {
  display: block;
}

.commonImageCeditTitle {
font-size: 14px;
font-weight: 500;
line-height: 28px;
text-align: left;
display: inline;
margin-right: 4px;
}

.commonImageCeditAgencyAuth {
font-size: 11px;
font-weight: 400;
line-height: 28px;
text-align: left;
display: inline-block;
}
.commonImageCeditAgencyAuth span{
  font-size: 11px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  display: inline-block;
}
.theme_3 .commonImageCeditTitle , .theme_3 .commonImageCeditAgencyAuth{ font-family: "Poppins", sans-serif;}
.theme_4 .commonImageCeditTitle , .theme_4 .commonImageCeditAgencyAuth{ font-family: "Montserrat", sans-serif;}
.theme_5 .commonImageCeditTitle , .theme_5 .commonImageCeditAgencyAuth{ font-family: "Roboto", sans-serif;}

.theme_3 .as-content-body .commonImageCeditAgencyAuth {
  font-size: 11px !important;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  display: inline-block;
}
.theme_3 .as-content-body .commonImageCeditTitle {
  font-size: 14px !important;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  display: inline;
  margin-right: 4px;
  }
  .theme_4 .themeTwoArticalShowSummeryWrap .commonImageCeditAgencyAuth{
    font-size: 11px !important;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    display: inline-block;
  }
  .theme_4 .themeTwoArticalShowSummeryWrap .commonImageCeditTitle{
    font-size: 14px !important;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    display: inline;
    margin-right: 4px;
  }

  .theme_5 .ThemeThreeArticalShowSummeryWrap .commonImageCeditTitle{
    font-size: 14px !important;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    display: inline;
    margin-right: 4px;
  }
  .theme_5 .ThemeThreeArticalShowSummeryWrap .commonImageCeditAgencyAuth{
    font-size: 11px !important;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    /* display: inline-block; */
    display: unset;
  }

  .theme_5 .ThemeThreeArticalShowSummeryWrap  .commonImageCeditAgencyAuth span{
    font-size: 11px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    display: inline-block;
    margin-bottom: 0px;
    color: #000;
    overflow: unset;
  }

/*---------Image credits ---------------*/

  /* common Amp credit style */
  .themeOneAmpCommonImagecreditMainWrapper{
    font-family: "Poppins", sans-serif;
  }
  .themeOneAmpCommonImagecreditMainWrapper{
    font-family: "Montserrat", sans-serif;
  }
  .themeThreeAmpCommonImagecreditMainWrapper{
    font-family: 'Roboto', sans-serif;
  }
  
    .ampCommonImagecreditMainWrapper {
      display: block;
  }
  
  .ampCommonImageCeditTitle {
      font-size: 14px;
      font-weight: 500;
      line-height: 28px;
      text-align: left;
      display: inline;
      margin-right: 4px;
  }
  
  .ampCommonImageCeditAgencyAuth {
      font-size: 11px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;
      display: inline-block;
  }
  
  .ampCommonImageCeditAgencyAuth span {
      font-size: 11px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      display: inline-block;
  }
    /* common  Amp credit style */


@media (max-width: 767px) {

  .theme_5 .ThemeThreeArticalShowSummeryWrap .commonImageCeditAgencyAuth{
    display: block;
  }

  .commonImageCeditTitle{
    display: block !important;
  }
  .modal-open {
    overflow: hidden;
  }

  .mt-44 {
    margin-top: 40px;
  }

  .mt-80 {
    margin-top: 50px;
  }


  .headingRow {
    margin-bottom: 14px;
  }

  .headingRow h3 {
    font-size: var(--fontSize-18);
  }

  .headingRow h3 a:before {
    top: 48%;
    height: 16px;
  }

  .mt-44:has(.col-md-8) {
    margin-top: 40px !important;
  }

  .ThemeTwoTrnHeading,
  .themeTwoCommonComponentsHeading {
    font-size: var(--fontSize-18);
  }

  .themeTwodateAgencyWrap {
    justify-content: start;
  }

  .gridForListingPage {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
  }

  /*------------ThemeThree Common css-----------_*/
  .theme_5 img:hover {
    transform: scale(1);
  }

  .themeThreedateAgencyWrap {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .ThemeThreeCommonAuthorWrapper {
    font-size: 10px;
    font-weight: 500;
    line-height: 17px;
    /* 170% */
  }

  .themeThreeHeroLayoutDateTimeAgency,
  .themeThreeHeroLayoutDateTimeAgency span {
    font-size: 10px;
    line-height: 17px;
  }

  .themeThreeCommonComponentsHeading {
    font-size: 28px;
    margin: 15px 0px 22px;
  }

  .themeThreeCommonComponentsHeading a {
    font-family: "Roboto", sans-serif;
    font-size: 28px;
  }

  /* 
  .theme_5, .HeroLayotuSkeleton {
    background: #fff;
  } */

  .HomeQuickViewkeleton, .HomeGridViewSkeleton {
    padding: 0px;
  }

  .theme_5 .tags-heading:before {
    content: "";
    width: 100%;
    bottom: -14px;
    position: absolute;
    height: 2px;
    background: #ed6037;
  }


  /*------------ThemeThree Common css-----------_*/
  /*------------Common Embed css-----------_*/
  .CommonEmbedSlider.slick-initialized .slick-slide {
    max-width: 186px;
    margin-right: 25px;
  }

  .CommonEmbedSlider.slick-initialized {
    padding: 20px 0px;
    background: #fff;
  }

  .ComonEmbedPollSubmitWrapper {
    margin-left: auto;
  }

  .theme_5 .ComonEmbedPoll .ComonEmbedPollHeading {
    display: block;
  }

  .theme_5 .ComonEmbedPoll .ComonEmbedPollHeading, .theme_5 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
    font-size: 16px;
  }

  .theme_5 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
    display: inline;
  }

  .theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap, .theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName span, .theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName {
    font-size: 12px;
  }

  .CommonEmbedArtileShowWrapper {
    background: #fff;
    padding: 20px;
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsisDesktop {
    display: none;
  }

  .theme_5 .CommonEmbedArtileShow .CommonEmbedArtileTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .theme_5 .CommonEmbedArtileShowWrapper .CommonEmbedSurveyQuizSynopsisMobile {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #606060;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .theme_5 .CommonEmbedFileDownload a {
    font-size: 14px;
  }

  .theme_5 .CommonEmbedFileDownload span {
    display: none;
  }

  .CommonEmbedInstaCustom iframe {
    width: 100%;
  }

  .CommonInsidePolInput {
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 0px 10px 0px 0px;
  }

  /*------------Common Embed css-----------_*/
  /*------------Common GroupEmbed css-----------_*/
  .CommonEmbedGroupSlider.slick-initialized .slick-slide {
    margin-right: 25px;
    min-width: 365px;
    max-width: 365px;
  }

  .theme_5 .CommonEmbedGroupSlider {
    padding: 40px 0px 40px 15px;
  }

  .theme_5 .CommonEmbedGroupSlider .slick-dots li.slick-active button {
    background-color: #000 !important;
  }

  .theme_5 .CommonEmbedGroupSlider .slick-dots li button:before {
    content: '';
  }

  .theme_5 .CommonEmbedGroupSlider .slick-dots li {
    margin: 0px;
  }

  /*------------Common Embed css-----------_*/
  /*---------End Of---Common Embed css-----------_*/
  /*--------THemeTwo-Common Embed css-----------_*/
  /*------------Common Embed css-----------_*/


  .theme_4 .ComonEmbedPoll .ComonEmbedPollHeading {
    display: block;
  }

  .theme_4 .ComonEmbedPoll .ComonEmbedPollHeading, .theme_4 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
    font-size: 16px;
  }

  .theme_4 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
    display: inline;
  }

  .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap, .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName span, .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName {
    font-size: 12px;
  }

  .CommonEmbedArtileShowWrapper {
    background: #fff;
    padding: 20px;
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsisDesktop {
    display: none;
  }

  .theme_4 .CommonEmbedArtileShow .CommonEmbedArtileTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .theme_4 .CommonEmbedArtileShowWrapper .CommonEmbedSurveyQuizSynopsisMobile {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #606060;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .theme_4 .CommonEmbedFileDownload a {
    font-size: 14px;
  }

  .theme_4 .CommonEmbedFileDownload span {
    display: none;
  }

  .theme_4 .CommonEmbedSlideShowMainWrapper .CommonEmbedSlideShowTitle {
    top: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    left: 15px;
  }


  /*------------Common Embed css-----------_*/
  /*------------Common GroupEmbed css-----------_*/
  .theme_4 .CommonEmbedGroupSlider {
    padding: 40px 15px 40px 15px;
  }

  /*------------Common Embed css-----------_*/
  /*---------End Of--THemeTwo-Common Embed css-----------_*/

  /*------------Common Embed css-----------_*/


  .theme_3 .ComonEmbedPoll .ComonEmbedPollHeading {
    display: block;
  }

  .theme_3 .ComonEmbedPoll .ComonEmbedPollHeading, .theme_3 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
    font-size: 16px;
  }

  .theme_3 .ComonEmbedPollHeading .ComonEmbedPollDefaultText {
    display: inline;
  }

  .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap, .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName span, .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizAuthorName {
    font-size: 12px;
  }

  .CommonEmbedArtileShowWrapper {
    background: #fff;
    padding: 20px;
    position: relative;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizSynopsisDesktop {
    display: none;
  }

  .theme_3 .CommonEmbedArtileShow .CommonEmbedArtileTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin-bottom: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .theme_3 .CommonEmbedArtileShowWrapper .CommonEmbedSurveyQuizSynopsisMobile {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #606060;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
  }

  .theme_3 .CommonEmbedFileDownload a {
    font-size: 14px;
  }

  .theme_3 .CommonEmbedFileDownload span {
    display: none;
  }

  .theme_3 .CommonEmbedSlideShowMainWrapper .CommonEmbedSlideShowTitle {
    top: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    left: 15px;
  }


  /*------------Common Embed css-----------_*/
  /*------------Common GroupEmbed css-----------_*/
  .theme_3 .CommonEmbedGroupSlider {
    padding: 40px 15px 40px 15px;
  }

  /*------------Common Embed css-----------_*/
  /*--------THemeOne-Common Embed css-----------_*/
  /*---------End Of--THemeOne-Common Embed css-----------_*/




  /*------------Common ThemeThree GroupEmbed css-----------_*/
  .CommonEmbedGroupSlider.slick-initialized .slick-slide {
    margin-right: 20px;
    min-width: 365px;
    max-width: 365px;
  }

  .theme_5 .CommonEmbedGroupSlider {
    padding: 40px 15px 40px 15px;
  }

  /*----------End-Common ThemeThree GroupEmbed css-----------_*/
  /*------------Common ThemeTwo GroupEmbed css-----------_*/
  .theme_4 .CommonEmbedGroupSlider {
    padding: 40px 15px 40px 15px;
  }

  /*-----------End Of-Common ThemeTwo GroupEmbed css-----------_*/

  /*------------Common ThemeOne GroupEmbed css-----------_*/
  .theme_3 .CommonEmbedGroupSlider {
    padding: 40px 15px 40px 15px;
  }

  /*-----------End Of-Common ThemeOne GroupEmbed css-----------_*/

  .theme_3 .CommonEmbedSlideeShowImageWrap {
    min-width: auto;
    height: 132px;
    overflow: hidden;
  }
}

@media (max-width: 420px) {

  /*---------ThemeThree---Common Embed css-----------_*/
  .theme_5 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap {
    display: block;
  }

  /*-------End of--ThemeThree---Common Embed css-----------_*/
  /*---------ThemeTwo---Common Embed css-----------_*/
  .theme_4 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap {
    display: block;
  }

  /*-------End of--ThemeTwo---Common Embed css-----------_*/
  /*---------Themeone---Common Embed css-----------_*/
  .theme_3 .CommonEmbedArtileShow .CommonEmbedSurveyQuizTimeWrap {
    display: block;
  }

  /*-------End of--Themeone---Common Embed css-----------_*/
}
 /*-------Login display none temp pagebuilder-----------_*/
.ThemeOneMainLoginFunctionWrap, .ThemeOneMainLoginFunctionWrapMobile, .ThemeThreeLoginMainWrapper, .ThemeTwoLoginMainWrapper, .themeTwoMainLoginFunctionWrapMobile {
  display: none;
}
 /*-------Login display none temp- pagebuilder----------_*/

 /*-------Add script css pagebuilder----------_*/
.accordian-wrapper{
  background-color: #fff;
}
 .adScriptWrapper .MuiInputBase-formControl {
  padding: 0px;
}
.label-adtextfield{
  color: #8a8a8a;
  margin-bottom: 6px;
}
 /*-------Add script css pagebuilder----------_*/